body {
  background-color: #f3f3f3; 
}

#root {
  height: 100%;
}

.animated {
  animation-duration: 400ms !important;
}

@keyframes slideInRightRightDrawer {
  from {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}

.slideInRightRightDrawer {
  animation-name: slideInRightRightDrawer;
}

@keyframes slideOutRightRightDrawer {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideOutRightRightDrawer {
  animation-name: slideOutRightRightDrawer;
}

.answerBlank {
  font-style: italic;
  opacity: 0.7;
}
